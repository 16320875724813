<template>
  <div
    v-if="data.length > 0"
    class="g-chart-container"
  >
    <h4 class="g-chart-container__title">
      Динамика  кол-ва выставленных и сыгранных аукционов
    </h4>
    <GChart
      type="BarChart"
      :data="chartData"
      :options="options"
      :settings="settings"
    />
  </div>
</template>

<script>
import {GChart} from 'vue-google-charts';

export default {
  name: 'AuctionsCategoryChart',
  components: {
    GChart,
  },
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
    period: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      settings: {language: 'ru', packages: ['corechart', 'line']},
      options: {
        explorer: {
          actions: ['dragToZoom', 'rightClickToReset'],
          axis: 'vertical',
          keepInBounds: true,
          maxZoomIn: 0.02,
        },
        chartArea: {width: '100%', height: '100%', backgroundColor: '#b8fffe'},
        height: 800,
        width: '100%',
        titlePosition: 'none',
        textStyle: {color: 'black', fontSize: 12},
        // colors: ['#b19ded', '#803054', '#2e77bf', '#cfd13f'],
        legend: {
          position: 'bottom',
          maxLines: 2,
          textStyle: {color: 'black', fontSize: 12},
        },

        vAxis: {

          gridlines: {
            count: 0,
            // color: 'none',
          },
          allowContainerBoundaryTextCutoff: true,
          minTextSpacing: 20,
          maxTextLines: 2,
          slantedText: true,
          showTextEvery: 1,
          textStyle: {
            fontSize: 12,
            color: 'black',
            bold: true,
            italic: false,
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      const result = [
        ['', {role: 'annotation'}, 'Кол-во рейсов, утвержденных', {calc: 'stringify',
          sourceColumn: 1,
          type: 'string',
          role: 'annotation'}, 'Кол-во рейсов, где был участником', {calc: 'stringify',
          sourceColumn: 2,
          type: 'string',
          role: 'annotation'}],
      ];
      this.data.forEach((item, key) => {
        result.push([
          key,
          item.name,
          Number(item.winnerAuctions),
          item.winnerAuctions ? item.winnerAuctions : null,
          Number(item.totalAuctions),
          item.totalAuctions ? item.totalAuctions : null,
        ]);
      });
      return result;
    },
  },
};
</script>

<style scoped  lang="scss">
.g-chart-container {
  position: relative;

  &__title {
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 10%;
    width: 80%;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  &__form-group {
    position: absolute;
    z-index: 1;
    top: 68px;
    left: 10%;
    width: 80%;
  }
}
</style>
